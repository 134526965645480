
.family-member-dialog {
    .modal-dialog {
        width: 400px;
    }
    .modal-body {
        label {
            margin-bottom: 4px;
            font-size: 12px;
            text-transform: capitalize;
        }
        label + div {
            display: flex;
            justify-content: space-between;
            margin-bottom: 30px;
            > span {
                font-size: 15px;
                &:first-of-type {
                    color: black;
                    font-weight: bold;
                }
                button {
                    text-transform: uppercase;
                    color: #009bff;
                    &.red {
                        color: #d50022;
                    }
                }
                div + div {
                    margin-top: 4px;
                }
            }
        }
    }
    .modal-footer {
        padding-top: 0;
        #release-child {
            margin-bottom: 20px;
        }
    }
}
