.sms-opt-in-login-create {
  overflow: auto;
}
.sms-opt-in-login-create .modal-dialog {
  margin: auto 0 !important;
}
.sms-opt-in-login-create .modal-footer .phone-number-usa-canada {
  padding-top: 15px;
  text-align: left;
  margin-bottom: 0;
}
.sms-opt-in-login-create .modal-footer .not-minor {
  text-align: left;
}
