.trusted-device-dialog .modal-desc {
  margin-bottom: 10px;
}
.trusted-device-dialog .modal-desc .warning {
  color: #e63f57;
  margin: 0;
  text-transform: capitalize;
}
.trusted-device-dialog .input-container {
  padding-top: 16px;
}
.trusted-device-dialog .modal-footer {
  padding-top: 0px;
}
