.backup-codes .modal-header {
  padding-bottom: 20px;
}
.backup-codes .generate-new {
  cursor: pointer;
  font-size: 12px;
  margin-left: 10px;
  text-decoration: underline;
}
.backup-codes .input-container {
  align-items: center;
  display: flex;
  justify-content: center;
}
.backup-codes .input-container input {
  margin-top: 10px;
  width: 320px;
}
.backup-codes .input-container .codes {
  align-items: center;
  background-color: #0c0c0c;
  display: flex;
  height: 149px;
  justify-content: center;
  width: 100%;
}
.backup-codes .input-container .codes .list {
  align-items: center;
  color: white;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  height: 100%;
  justify-content: space-between;
  padding: 20px;
  width: 75%;
}
.backup-codes .input-container .codes .list .code {
  height: 19px;
}
.backup-codes .input-container .codes .list .code.disabled {
  color: #808080;
}
.backup-codes .input-container .codes .list .code.disabled .used {
  opacity: 1;
}
.backup-codes .input-container .codes .list .code .half {
  padding-right: 5px;
}
.backup-codes .input-container .codes .list .code .used {
  font-size: 12px;
  margin-left: 10px;
  opacity: 0;
}
.backup-codes .input-container .codes .page-spinner-wrapper {
  top: calc(100% - 100px);
}
.backup-codes .modal-footer {
  padding-top: 0;
}
@media (max-width: 420px) {
  .backup-codes .list {
    width: 100% !important;
  }
}
