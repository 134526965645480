
.create-account-dialog {
    .modal-body {
        p.input-id {
            color: red;
            font-weight: bold;
        }
        p:not(:last-child) {
            margin-bottom: 20px;
        }
    }
}
