.need-password-dialog {
  color: black;
}
.need-password-dialog .modal-body p.input-id {
  color: red;
  font-weight: bold;
}
.need-password-dialog .modal-body p:not(:last-child) {
  margin-bottom: 20px;
}
.need-password-dialog .recaptcha-attribution {
  margin-top: 40px;
  font-size: 10px;
  color: #737373;
}
@media (min-width: 768px) {
  .need-password-dialog .modal-dialog {
    width: 630px;
  }
}
