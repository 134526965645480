
.trusted-device-dialog {
    .modal-desc {
        margin-bottom: 10px;
    }
    .input-container {
        padding-top: 16px;
    }
    .modal-footer {
        padding-top: 0px;
    }
}
