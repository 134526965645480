
@import '~@arena/toolkit/src/less/toolkit/toolkit.less';
@import './less/global';
@import './less/responsive';

#errorContainer {
    margin-bottom: 1px;
}
.error-section {
    display: flex;
    justify-content: center;
    p {
        margin-bottom: 0;
        padding-top: 2px;
    }
}
