
.tooltip-anchor {
    background-color: #4f5159;
    border-radius: 50%;
    cursor: pointer;
    display: flex;
    height: 14px;
    justify-content: center;
    margin-left: 10px;
    width: 14px;
    outline: none;
    &--custom-icon {
        background-color: transparent !important;
    }
    &.hover,
    &.open {
        background-color: #7b7f8c;
    }
    &:focus {
        outline: none;
    }
    > span {
        align-items: center;
        color: rgba(255, 255, 255, 0.8);
        display: grid;
        font-size: 8.5px;
        font-weight: bold;
        justify-content: center;
        width: 14px;
    }
    .tooltip-caret {
        height: 0;
        width: 0;
    }
    .tooltip-body {
        align-content: center;
        background: #121212;
        border-radius: 5px;
        box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.5);
        cursor: default;
        display: flex;
        margin-top: 20px;
        position: relative;
        width: 291px;
        z-index: 1;
        .tooltip-content {
            color: #bfbfbf;
            font-size: 11px;
            line-height: 1.64;
            padding: 15px 20px;
        }
        .tooltip-close {
            cursor: pointer;
            height: 8px;
            opacity: 0.6;
            position: absolute;
            right: 10px;
            top: 10px;
            width: 7px;
            &:hover {
                opacity: 1;
            }
        }
    }
}
