.family-member-dialog .modal-dialog {
  width: 400px;
}
.family-member-dialog .modal-body label {
  margin-bottom: 4px;
  font-size: 12px;
  text-transform: capitalize;
}
.family-member-dialog .modal-body label + div {
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
}
.family-member-dialog .modal-body label + div > span {
  font-size: 15px;
}
.family-member-dialog .modal-body label + div > span:first-of-type {
  color: black;
  font-weight: bold;
}
.family-member-dialog .modal-body label + div > span button {
  text-transform: uppercase;
  color: #009bff;
}
.family-member-dialog .modal-body label + div > span button.red {
  color: #d50022;
}
.family-member-dialog .modal-body label + div > span div + div {
  margin-top: 4px;
}
.family-member-dialog .modal-footer {
  padding-top: 0;
}
.family-member-dialog .modal-footer #release-child {
  margin-bottom: 20px;
}
