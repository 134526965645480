
.trusted-device-dialog {
    .modal-desc {
        margin-bottom: 10px;
        .warning {
            color: #e63f57;
            margin: 0;
            text-transform: capitalize;
        }
    }
    .input-container {
        padding-top: 16px;
    }
    .modal-footer {
        padding-top: 0px;
    }
}
