
#launcher-footer {
    .region-selector {
        color: #ffffff;
        margin-bottom: 12px;
        text-shadow: 0px 1px 1px rgba(0, 0, 0, 0.59);
        display: table;
        margin: 10px auto 20px;
        > * {
            display: table-cell;
            vertical-align: middle;
        }
        > span {
            opacity: 0.5;
        }
        .select-box {
            width: 193px;
            padding: 0 10px 0 18px;
            .form-group {
                margin: 0;
                > label {
                    display: none;
                }
            }
            .form-control {
                padding: 4px 8px;
                height: 30px;
                color: rgba(255, 255, 255, 0.5);
                text-align: left;
                font-size: 12px;
                line-height: 20px;
                background-color: black;
                border: 1px solid rgba(255, 255, 255, 0.1);
                .caret {
                    right: 10px;
                    top: calc(50% - 2px);
                    border-top: 5px dashed;
                    border-right: 5px solid transparent;
                    border-left: 5px solid transparent;
                }
                .btn-content {
                    display: inline;
                    vertical-align: middle;
                }
                &:hover {
                    border: 1px solid rgba(255, 255, 255, 0.5);
                    .caret {
                        color: rgba(255, 255, 255, 0.8);
                    }
                }
            }
            .open {
                .caret {
                    border-top: initial;
                    border-bottom: 5px dashed;
                }
            }
            ul.dropdown-menu {
                top: initial;
                bottom: 30px;
                background-color: black;
                border: 1px solid rgba(255, 255, 255, 0.005);
                > li {
                    background-color: black;
                    a {
                        color: rgba(255, 255, 255, 0.5);
                        &.highlight {
                            color: white;
                            background-color: rgba(255, 255, 255, 0.05);
                        }
                    }
                    &:hover {
                        background-color: rgba(255, 255, 255, 0.05);
                        a {
                            background-color: rgba(255, 255, 255, 0.05);
                        }
                    }
                }
                &:hover {
                    border: 1px solid rgba(255, 255, 255, 0.3);
                }
            }
        }

        .tooltip-anchor {
            position: relative;
            .tooltip-body {
                position: absolute;
                left: 0;
                bottom: 30px;
            }
        }

        .btn-gray {
            color: #ffffff;
            background-color: #393c43;
            border: solid 1px rgba(0, 0, 0, 0.2);
            &:hover {
                color: #ffffff;
                background-color: #4b4e57;
                border: solid 1px rgba(0, 0, 0, 0.2);
            }
            &:active {
                color: rgba(255, 255, 255, 0.6);
                background-color: #2c2e33;
            }
        }
        .btn-sm {
            height: 30px;
            min-width: 120px;
            width: auto;
            padding: 0 10px;
            margin: 0;
            font-weight: normal;
            line-height: 20px;
        }
    }
}
