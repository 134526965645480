
.need-password-dialog {
    color: black;
    .modal-body {
        p.input-id {
            color: red;
            font-weight: bold;
        }
        p:not(:last-child) {
            margin-bottom: 20px;
        }
    }

    .recaptcha-attribution {
        margin-top: 40px;
        font-size: 10px;
        color: #737373;
    }

    @media (min-width: 768px) {
        .modal-dialog {
            width: 630px;
        }
    }
}
