
.trusted-device-dialog {
    .modal-body {
        .modal-desc {
            margin-bottom: 10px;
        }

        .device-name-container {
            margin-top: 40px;
            .device-name {
                margin-left: 20px;
                font-weight: bold;
                color: #000;
            }
        }
    }
}
