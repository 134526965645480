.minor-linking-dialog .modal-dialog {
  width: 640px;
}
.minor-linking-dialog .modal-body {
  white-space: pre-line;
  text-align: center;
}
.minor-linking-dialog .modal-body .link {
  text-decoration: underline;
  cursor: pointer;
}
