.legacy-support .cs-code {
  display: flex;
  margin-top: 20px;
}
.legacy-support .cs-code span:first-child {
  margin-right: 5px;
}
.legacy-support .cs-code .code {
  font-weight: bold;
  margin-right: 10px;
}
.legacy-support .cs-code .copy-wrapper {
  display: inline-block;
}
.legacy-support .cs-code .copy-wrapper span.copy {
  cursor: pointer;
  opacity: 1;
  position: absolute;
  text-decoration: underline;
  transition: opacity 0.5s linear;
}
.legacy-support .cs-code .copy-wrapper .copied {
  opacity: 0 !important;
}
