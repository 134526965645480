
.minor-linking-dialog {
    .modal-dialog {
        width: 640px;
    }
    .modal-body {
        white-space: pre-line;
        text-align: center;
        .link {
            text-decoration: underline;
            cursor: pointer;
        }
    }
}
