
.sms-opt-in-login-create {
    overflow: auto;
    .modal-dialog {
        margin: auto 0 !important;
    }
    .modal-footer {
        .phone-number-usa-canada {
            padding-top: 15px;
            text-align: left;
            margin-bottom: 0;
        }
        .not-minor {
            text-align: left;
        }
    }
}
