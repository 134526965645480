
.modal-mfa-success {
    .backup-code {
        margin-top: 20px !important;
    }
}
.ru,
.es {
    .modal-mfa-success {
        .submit-button-group {
            .btn-block {
                font-size: 11px;
            }
        }
    }
}
