
.captcha-box {
    transform: scale(1.06);
    transform-origin: 0 0;
    margin-bottom: 7px;
}
@media (max-width: 740px) {
    .captcha-box {
        transform: scale(1.19);
        transform-origin: 0 0;
        margin-bottom: 15px;
    }
    .toy {
        .captcha-box {
            transform: scale(1.06);
            transform-origin: 0 0;
        }
    }
}
@media (max-width: 400px) {
    .captcha-box {
        width: 320px;
        margin: 0 auto 7px;
        transform: scale(1.06);
        transform-origin: 0 0;
    }
}
