// Global CSS for basic layout //

@staticImagePath: '/account/static/images';

html,
body {
    background-image: radial-gradient(circle farthest-side at center, #2c2c2c 0%, #141414 100%);
    background-attachment: fixed;
    background-repeat: no-repeat;
    background-color: #141414;
    width: 100%;
    height: 100%;
    min-width: 320px;
    overflow-x: hidden;
    padding: 0;
    #body {
        display: block;
    }
    #globalNavigation {
        display: none;
        &.region-switcher {
            display: block;
            .global-navigation {
                display: none;
            }
            .region-switcher-container {
                border-bottom: 1px solid #1e1e1e;
            }
        }
    }
    #globalFooter {
        position: absolute; //temp positioning
        bottom: 0;
    }
    .one {
        #globalNavigation,
        #globalFooter {
            display: none;
        }
    }
    .murk {
        font-size: 12px;
        label {
            font-size: 12px;
        }

		//noinspection CssInvalidPseudoSelector
		input:-internal-autofill-selected, input:-internal-autofill-previewed {
			-webkit-box-shadow: 0 0 0 30px #0c0c0c inset !important;
			opacity: 0.7;
			-webkit-text-fill-color: white !important;
			transition:  -webkit-box-shadow ease 0s;
		}

	}
    #account {
        position: relative;
        min-height: 100%;

        .agree-tou {
            margin-bottom: 24px;
            a {
                text-decoration: underline;
                color: inherit;
                &:hover {
                    color: #24aef2;
                }
            }
        }
        .input-check {
            position: absolute;
            left: 0;
        }
        .checkbox-label {
            display: inline-block;
            padding-left: 30px;
            padding-top: 4px;
            line-height: 1.5em;
        }
        &.launcher,
        &.toy,
        &.developers,
        &.steam {
            nav,
            #globalFooter {
                display: none;
            }
            .settings-contents-wrapper {
                margin-bottom: 30px;
            }
        }
    }

    .setting-wrapper {
        max-width: 1024px;
        margin: 0 auto;
        padding: 60px 32px;
        position: relative;
        label {
            font-size: 16px;
        }
    }

    &.setting,
    &.create-change-password,
    &.change-password,
    &.change-email,
    &.change-name,
    &.verify-account,
    &.verify-code-2fa-settings,
    &.auth-types-2fa-settings,
    &.manage-trusted-devices,
    &.verify-backup-code-2fa-settings,
    &.manage-payment-methods {
        #globalNavigation {
            display: block;
        }
        #account {
            background: transparent none !important;
            .dev-info {
                top: 70px;
            }
        }
    }

    .tw.zh {
        font-family: '微軟正黑體', 'Microsoft JhengHei', Arial, Helvetica, sans-serif;
        * {
            font-family: inherit !important;
        }
    }
}

body {
    margin: 0 auto;

    &::-webkit-scrollbar {
        width: 10px;
    }

    &::-webkit-scrollbar-track {
        background: #141414;
    }

    &::-webkit-scrollbar-thumb {
        background: #737373;
        border-radius: 1px;
    }
}

button {
    background-color: transparent;
    border: none;
    &:focus {
        outline: 0;
    }
    &:disabled {
        cursor: not-allowed !important;
        div {
            cursor: not-allowed !important;
        }
        .icon-help {
            opacity: 0.4;
        }
    }
}

button[disabled] {
    &:extend(button:disabled);
}

.input-container {
    padding: 8px 0 0;
}
.setting-wrapper {
    .divider {
        border-top: 1px solid #383838;
        margin: 30px 0 15px;
    }
}
.help {
    margin-top: 20px;
    line-height: 1.8em;

    a {
        .icon-help {
            width: 8px;
            height: 8px;
            opacity: 0.8;
        }
        &:hover {
            .icon-help {
                opacity: 1;
            }
        }
    }
}
.well-lg,
.well-sm {
    &.info {
        position: relative;
        background-color: #000;
        font-size: 14px;
        margin-top: 20px;
        line-height: 1.5em;
        border-radius: 3px;
        h5 {
            color: #fff;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
    }
}
.resend-email {
    cursor: pointer;
}
.resend-wrapper {
    margin-bottom: 20px;
    span {
        color: #ccc;
    }
}
.link-cancel {
    margin-top: 15px;
}

.page-spinner-wrapper {
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translateY(-50%);
    transform: translateX(-50%);
    margin: 0 auto;
    width: 60px;
    height: 60px;
    z-index: 10000;
    .spinner-circle {
        width: 60px;
        height: 60px;
        border: 10px solid #999;
        border-top: 10px solid #0078ff;
    }
}

.fade-enter-active,
.fade-leave-active {
    transition: all 0.5s ease-out;
}

.fade-enter,
.fade-leave-to {
    opacity: 0;
}

.checkbox-label {
    padding-top: 6px;
}

#error,
#maintenance {
    padding: 150px 20px 40px;

    .logo-container {
        width: 67px;
        margin: 0 auto 100px;
    }
    .logo {
        width: 67px;
        height: 10px;
    }
    .error-logo {
        width: 122px;
        height: 134px;
        margin: 0 auto 50px;
        display: block;
    }
    .error-desc {
        font-size: 16px;
        margin-top: 20px;
    }
    .back-to-home {
        display: block;
        margin: 50px auto 0;
    }
}
.login,
.create-account {
    #account {
        background: transparent no-repeat center top;
        background-size: cover;
        &.general-bg.global {
            // display the background image only for WEST when there are no landing or product id background images
            /// commented out due to other launching games (KartRider)
            // background-image: url('~@/images/background.jpg');
            // &.web {
            //     background-position-y: -50px;
            // }
            // @media (max-width: 1024px) {
            //     background-image: url('~@/images/background-1000.jpg');
            // }
            // @media (min-width: 1000px) and (max-width: 1024px) and (max-height: 740px) {
            //     &.launcher {
            //         background-position-y: -140px;
            //     }
            // }
            ///
        }
        &.marvel-machine {
            background-image: url('~@/images/marvel-machine/stars.png'),
                                url('~@/images/marvel-machine/signup-bg-2000x1160-dark.png');
            background-size: cover;
            background-position: center;

            .contents {
                padding-top: 20px;
            }

            .custom-title {
                margin-top: 15px;
            }
        }
        @media (max-width: 740px) {
            &.marvel-machine {
                background-image: url('~@/images/marvel-machine/stars-bg-mobile.png'),
                                    url('~@/images/marvel-machine/signup-bg-1000x1160.png');
                background-position: center right 40%;
            }
        }
        &.game-landing {
            .web {
                padding: 60px 0 60px;
            }
            #errorContainer {
                position: absolute;
                top: 0;
                width: 100%;
            }
            #globalFooter {
                display: none;
            }
            @media (max-width: 420px) {
                background-image: none;
            }
        }
        .product-logo {
            background: transparent no-repeat center center;
            background-size: contain;
            position: absolute;
            top: -85px;
            right: 0;
            width: 135px;
            height: 95px;
            margin: 0 auto;
            &.product-50203 {
                background-image: url('~@/images/logos/logo-kart-rider.png');
                top: -120px !important;
            }
            &.product-10200 {
                background-image: url('~@/images/logos/logo-mabinogi.png');
                width: 120px;
            }
            &.product-10100 {
                background-image: url('~@/images/logos/logo-maplestory.png');
            }
            &.product-10300 {
                background-image: url('~@/images/logos/logo-vindictus.png');
                width: 200px;
                top: -78px;
            }
        }
        &.developers {
            background: none;
            .product-logo {
                background: none;
            }
        }
    }
}

// Toy account login and creation specific.
.toy,
.developers {
    .contents {
        max-width: 360px !important;
        .contents-wrapper {
            margin-bottom: 10px !important;
        }
        .custom-title {
            display: none;
        }
        .legacy-tpa-checkbox {
            display: none;
        }
    }
    .footer {
        position: relative !important;
        padding: 0px 10px !important;
        width: 320px !important;
        margin: 0 auto !important;
        background-color: #0a0a0a !important;
        .nexon-logo-footer,
        .copyright {
            display: none !important;
        }
        .region-selector {
            padding-top: 20px;
            padding-bottom: 22px;
            margin-top: 0px !important;
            & + div {
                margin-top: -22px;
                .locale {
                    padding-top: 0px;
                }
            }
        }
        .locale {
            padding-top: 10px;
            padding-bottom: 2px;
            width: 100%;
            .icon-locale {
                position: absolute;
                left: 0;
            }
        }
        ul {
            top: auto !important;
            left: 83px !important;
        }
    }
}

.developers {
    .custom-title {
        display: block !important;
    }
}

// google optimize related class.
.async-hide {
    opacity: 0 !important;
}
.dialog-mask {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.33);
    z-index: 10000;
    .modal-dialog {
        margin: 0 !important;
    }
}
.grecaptcha-badge {
    display: none;
}

.custom-checkbox.large {
    .input-check {
        height: 20px;
        width: 20px;
        &:after {
            left: 6px;
            top: 1px;
            width: 7px;
            height: 12px;
            border-width: 0 3px 3px 0;
        }
    }
}
.disabled {
    pointer-events: none;
}

.launcher {
    // make smaller the dropdowns height when launcher height size is small
    .dropdown-menu {
        @media (max-height: 750px) {
            max-height: 350px;
        }
        @media (max-height: 700px) {
            max-height: 300px;
        }
        @media (max-height: 600px) {
            max-height: 260px;
        }
    }
}

#onetrust-consent-sdk {
    display: none;
}
#account.web.global ~ #onetrust-consent-sdk {
    display: block;
}