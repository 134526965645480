.trusted-device-dialog .modal-body .modal-desc {
  margin-bottom: 10px;
}
.trusted-device-dialog .modal-body .device-name-container {
  margin-top: 40px;
}
.trusted-device-dialog .modal-body .device-name-container .device-name {
  margin-left: 20px;
  font-weight: bold;
  color: #000;
}
