// Element size adjust for screen size //
/* eslint-disable */

@staticImagePath: '/static/images';

@media (max-width: 1024px) {
    .setting-wrapper {
        padding: 30px !important;
    }
}
@media (max-width: 740px) {
    .column {
        &.pull-left,
        &.pull-right {
            float: none !important;
            margin: 0 auto;
        }
    }
    .alert {
        position: relative;
    }

    .login,
    .create-account {
        #account {
            .product-logo {
                &.product-10300 {
                    width: 180px;
                }
            }
        }
    }
}
