
.backup-codes {
    .modal-header {
        padding-bottom: 20px;
    }
    .generate-new {
        cursor: pointer;
        font-size: 12px;
        margin-left: 10px;
        text-decoration: underline;
    }
    .input-container {
        align-items: center;
        display: flex;
        justify-content: center;
        input {
            margin-top: 10px;
            width: 320px;
        }
        .codes {
            align-items: center;
            background-color: #0c0c0c;
            display: flex;
            height: 149px;
            justify-content: center;
            width: 100%;
            .list {
                align-items: center;
                color: white;
                display: flex;
                flex-direction: column;
                flex-wrap: wrap;
                height: 100%;
                justify-content: space-between;
                padding: 20px;
                width: 75%;
                .code {
                    height: 19px;
                    &.disabled {
                        color: #808080;
                        .used {
                            opacity: 1;
                        }
                    }
                    .half {
                        padding-right: 5px;
                    }
                    .used {
                        font-size: 12px;
                        margin-left: 10px;
                        opacity: 0;
                    }
                }
            }
            .page-spinner-wrapper {
                top: ~'calc(100% - 100px)';
            }
        }
    }
    .modal-footer {
        padding-top: 0;
    }
}
@media (max-width: 420px) {
    .backup-codes .list {
        width: 100% !important;
    }
}
