
.footer {
    background-color: #262626;
    padding: 16px 20px 0;
    font-size: 12px;
    text-align: center;
    position: absolute; //temp positioning
    bottom: 0;
    width: 100%;

    .copyright {
        vertical-align: top;
        display: inline-block;
        margin: 2px 5px 8px;
        line-height: 1.5em;
    }
    .locale {
        display: inline-block;
        position: relative;
        margin: 2px 5px 10px;
        &.show-languages,
        .show-languages {
            display: inline-block;
        }
        .icon-locale {
            width: 15px;
            height: 15px;
            margin-right: 10px;
        }
        .current-language {
            cursor: pointer;
            &:hover {
                color: #00decb;
            }
        }
        span {
            margin-top: 2px;
            vertical-align: top;
            display: inline-block;
        }
    }
    .languages {
        display: none;
        position: absolute;
        width: 120px;
        text-align: left;
        bottom: -8px;
        left: 25px;

        box-shadow: 3px 3px 15px 3px #000000;

        background-color: #161616;

        li {
            position: relative;
            line-height: 2em;
            &:hover {
                background-color: #00decb;
            }
            a {
                display: inline-block;
                padding: 2px 15px;
                cursor: pointer;
            }
        }
    }
}
.logo {
    width: 100px;
    height: 25px;
    margin: 0 5px 10px;
}
.nexon-logo-footer {
    vertical-align: top;
    display: inline-block;
}
@media (max-width: 480px) {
    .footer {
        padding: 20px 10px 10px;
        .logo,
        .locale,
        .copyright {
            margin-bottom: 10px;
        }
    }
}
