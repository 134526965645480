
.legacy-support {
    .cs-code {
        display: flex;
        margin-top: 20px;
        span:first-child {
            margin-right: 5px;
        }
        .code {
            font-weight: bold;
            margin-right: 10px;
        }
        .copy-wrapper {
            display: inline-block;
            span.copy {
                cursor: pointer;
                opacity: 1;
                position: absolute;
                text-decoration: underline;
                transition: opacity 0.5s linear;
            }
            .copied {
                opacity: 0 !important;
            }
        }
    }
}
